<template>
  <div class="edit-post">
    <Loading class="overlay" :active.sync="visible"></Loading>
    <!-- Edit -->
    <div class="edit">
      <div class="breadcrumb">
        <span v-if="post && post.private === 0" class="back" @click="$router.push('/forums')">Forums / </span>
        <span v-else-if="post && post.private === 1" class="back" @click="$router.push('/groups')">Groups / </span>
        <span v-if="post && post.forumName" @click="backToParent" class="back">{{ post.forumName }} / </span>
        <span class="blue">Edit Post</span>
      </div>
      <div class="about">
        <div class="about-title">Edit Post</div>
      </div>
      <div class="body">
        <div class="post">
          <div class="title">
            <span class="header">Post Title*</span>
            <input type="text" v-model="edit.title" placeholder="Enter post title" />
          </div>
          <div class="body">
            <span class="header">Post Body*</span>
            <div class="wysiwyg">
              <ckeditor v-model="edit.content" :config="editorConfig"></ckeditor>
              <div v-if="ogTag && ogTag.ogImage && ogTag.ogImage.url && !ogTag.error" class="links">
                <div class="image-container">
                  <div class="img">
                    <img
                      v-if="
                        ogTag.ogImage &&
                        ogTag.ogImage.url &&
                        (ogTag.ogImage.url.endsWith('.png') ||
                          ogTag.ogImage.url.endsWith('.jpg') ||
                          ogTag.ogImage.url.endsWith('.jpeg'))
                      "
                      :src="ogTag.ogImage.url"
                    />
                    <NoThumbnail v-else />
                  </div>

                  <XIcon @click="ogTag = null" class="closeOGTag" />
                </div>
                <div class="meta">
                  <div class="left">
                    <div v-if="ogTag.ogTitle" class="title">{{ ogTag.ogTitle }}</div>
                    <div v-if="ogTag.ogDescription" class="description">
                      {{ ogTag.ogDescription }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="autocomplete" class="autocomplete">
                <Multiselect
                  ref="vms"
                  class="chip-container"
                  v-model="taggedUser"
                  placeholder="Tag a User"
                  label="lastName"
                  :custom-label="customLabel"
                  track-by="id"
                  :options="usersNotMe"
                  :multiple="true"
                ></Multiselect>
              </div>
            </div>
          </div>
          <div class="upload-attachments">
            <div class="header">Attachments</div>
            <div v-if="edit.attachments.length < 3" class="upload">
              <div class="left">No file selected</div>
              <div class="right">
                <UploadSVG />
                <span>Upload File</span>
                <input
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpg, image/jpeg, image/gif, video/mp4"
                  @change="onFileSelected"
                />
              </div>
            </div>
            <div v-if="edit.attachments.length < 2" class="upload">
              <div class="left">No file selected</div>
              <div class="right">
                <UploadSVG />
                <span>Upload File</span
                ><input
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpg, image/jpeg, image/gif, video/mp4"
                  @change="onFileSelected"
                />
              </div>
            </div>
            <div v-if="edit.attachments.length < 1" class="upload">
              <div class="left">No file selected</div>
              <div class="right">
                <UploadSVG />
                <span>Upload File</span
                ><input
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpg, image/jpeg, image/gif, video/mp4"
                  @change="onFileSelected"
                />
              </div>
            </div>
          </div>
          <div v-if="edit.attachments.length > 0" class="thumbnails">
            <div v-if="edit.attachments.length > 0" class="thumbnail-container">
              <div v-if="edit.attachments[0].fileName" class="fileName">{{ edit.attachments[0].fileName }}</div>
              <a v-else :href="`${bucketName}${edit.attachments[0].filePath}`" target="_blank">
                <div
                  :style="`background-image: url(${bucketName}${edit.attachments[0].thumbnail})`"
                  class="attachment"
                ></div>
              </a>
              <div class="remove" @click="removeAttachment(0)">Remove</div>
            </div>
            <div v-if="edit.attachments.length > 1" class="thumbnail-container">
              <div v-if="edit.attachments[1].fileName" class="fileName">{{ edit.attachments[1].fileName }}</div>
              <a v-else :href="`${bucketName}${edit.attachments[1].filePath}`" target="_blank">
                <div
                  :style="`background-image: url(${bucketName}${edit.attachments[1].thumbnail})`"
                  class="attachment"
                ></div>
              </a>
              <div class="remove" @click="removeAttachment(1)">Remove</div>
            </div>
            <div v-if="edit.attachments.length > 2" class="thumbnail-container">
              <div v-if="edit.attachments[2].fileName" class="fileName">{{ edit.attachments[2].fileName }}</div>
              <a v-else :href="`${bucketName}${edit.attachments[2].filePath}`" target="_blank">
                <div
                  :style="`background-image: url(${bucketName}${edit.attachments[2].thumbnail})`"
                  class="attachment"
                ></div>
              </a>
              <div class="remove" @click="removeAttachment(2)">Remove</div>
            </div>
          </div>
          <div v-if="post && post.poll && post.poll.pollUuid" class="poll">
            <div class="header">User Poll</div>
            <div class="poll-inner">
              <label class="title no-margin">Question*</label>
              <input type="text" v-model="post.poll.question" placeholder="Type your question here..." disabled />
              <div class="flex-input-wrapper">
                <div class="input-wrapper small-width">
                  <label class="sudo-header title lessMargin" for="challenge_live_date">End Date & Time*</label>
                  <Datepicker
                    id="challenge_live_date"
                    format="MM-dd-yy"
                    placeholder="MM-DD-YY"
                    v-model="newEndDate"
                    :disabledDates="{ to: new Date(Date.now() - 8640000) }"
                  ></Datepicker>
                </div>

                <div class="input-wrapper small-width no-label">
                  <Timepicker
                    id="challenge_live_time"
                    placeholder="12:00 AM"
                    format="hh:mm A"
                    v-model="newEndTime"
                  ></Timepicker>
                </div>

                <div class="input-wrapper time-width no-label">
                  <input type="text" placeholder="EST" disabled />
                </div>
              </div>
              <div v-for="(option, index) in post.poll.options" :key="index" class="question">
                <label class="title">Option {{ index + 1 }}*</label>
                <input v-model="option.text" :placeholder="`Option ${index + 1}`" disabled />
              </div>
            </div>
          </div>
          <div class="button">
            <div class="primary-btn" @click="editPost" :class="{ disabled: !edit.title || !edit.content || uploading }">
              <span v-if="!uploading">Save Post</span>
              <span v-else>Uploading asset...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import UploadSVG from '@/assets/icons/upload_icon.svg';
import NoThumbnail from '@/assets/icons/no_thumbnail.svg';
import CKEditor from 'ckeditor4-vue/dist/legacy';
import Multiselect from 'vue-multiselect';
import XIcon from '@/assets/icons/x_icon.svg';
import Datepicker from 'vuejs-datepicker';
import Timepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import { mapGetters } from 'vuex';

export default {
  name: 'edit-post',
  components: {
    Loading,
    UploadSVG,
    XIcon,
    Datepicker,
    Timepicker,
    NoThumbnail,
    ckeditor: CKEditor.component,
    Multiselect,
  },
  data() {
    return {
      post: null,
      edit: {
        id: null,
        title: null,
        content: null,
        attachments: [],
      },
      newEndDate: null,
      newEndTime: '',
      visible: false,
      taggedUser: null,
      uploading: false,
      ogTag: null,
      editorConfig: {
        toolbarGroups: [
          { name: 'clipboard', groups: ['undo'] },
          { name: 'styles', groups: ['styles'] },
          { name: 'basicstyles', groups: ['bold, italic'] },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'align'],
          },
        ],
        contentsCss: '/ckeditor/content.css',
        extraAllowedContent: 'span(*)',
        extraPlugins: 'mentions',
        mentions: [
          {
            feed: this.mentionsFeed,
            marker: '@',
            itemTemplate:
              '<li data-id="{id}"><span class="name">{name}</span> <span class="dealer">({dealer})</span></li>',
            outputTemplate: '<span class="user-tag" data-id="{id}">@{firstName}{lastName}{id}</span>&nbsp; ',
          },
        ],
        removeButtons: 'Cut,Copy,Paste,Underline,Strike,Subscript,Superscript,PasteFromWord,PasteText,Styles',
        removePlugins: 'PasteFromWord, resize',
        pasteAsPlainText: false,
      },
    };
  },
  computed: {
    ...mapGetters(['users']),
    bucketName() {
      return `${process.env.VUE_APP_BUCKET_URL}`;
    },
    usersNotMe() {
      if (this.users) {
        const users = this.users.filter(
          (user) => user.id.toString() !== this.$store.state.User.userInfo.userID.toString(),
        );
        users.unshift({ id: -1, firstName: 'admin', lastName: '', dealerName: 'Tag all admins of this group' });
        users.unshift({ id: -2, firstName: 'academy', lastName: '', dealerName: 'Tag all VW Academy admins' });
        return users;
      }
      return [];
    },
    autocomplete() {
      return false;
    },
    firstValidURL() {
      if (this.edit && this.edit.content) {
        const match = this.edit.content.match(
          // eslint-disable-next-line no-useless-escape
          /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        );
        if (match && match.length) {
          if (match[0].startsWith('https://')) return match[0].replace('&nbsp', '');
          if (match[0].startsWith('http://')) {
            const removedHttp = match[0].replace('http://', '');
            return `https://${removedHttp}`.replace('&nbsp', '');
          }
          return `https://${match[0].replace('&nbsp', '')}`;
        }
      }
      return null;
    },
  },
  methods: {
    getFormattedDate(endDate, endTime) {
      const date = new Date(endDate);
      const splitTime = endTime.split(':');
      let hours = splitTime[0];
      const splitMinutes = splitTime[1].split(' ');
      const minutes = splitMinutes[0];
      const a = splitMinutes[1];
      if (a === 'PM') {
        hours = (parseInt(hours, 10) + 12).toString();
      }
      date.setHours(parseInt(hours, 10));
      date.setMinutes(parseInt(minutes, 10));
      return date.toISOString();
    },
    backToParent() {
      if (this.post && this.post.private === 0) {
        this.$router.push(`/forum/${this.post.forumId}`);
      } else if (this.post && this.post.private === 1) {
        this.$router.push(`/group/${this.post.forumId}`);
      }
    },
    customLabel({ firstName, lastName, id }) {
      return `${firstName.replace(/ /g, '')}${lastName.replace(/ /g, '')}${id}`;
    },
    onFileSelected(event) {
      this.uploading = true;
      // eslint-disable-next-line prefer-destructuring
      const file = event.target.files[0];
      const fileName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const binary = e.srcElement.result;
        const x = binary.split(';');
        const fileType = x[0].substring(5, x[0].length);
        // get presigned URL
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/media`,
            { contentType: fileType },
            {
              headers: { authorization: sessionStorage.getItem('vwcup-token') },
            },
          )
          .then((response) => {
            // handle success
            const { url } = response.data.data;
            axios
              .put(`${url}`, file, { headers: { 'Content-Type': fileType } })
              .then(() => {
                const bucketName = `${process.env.VUE_APP_BUCKET_URL}`;
                this.edit.attachments.push({
                  id: response.data.data.id,
                  thumbnail: `${bucketName}${response.data.data.thumbnail}`,
                  fileName: this.shortenFileName(fileName),
                });
                this.uploading = false;
              })
              .catch((error) => {
                console.error(error);
                if (error.response.data.error === 'jwt expired') {
                  this.$store.dispatch('logout')();
                }
                this.uploading = false;
              });
          })
          .catch((error) => {
            // handle error
            console.error(error);
            if (error.response.data.error === 'jwt expired') {
              this.$store.dispatch('logout')();
            }
            this.uploading = false;
          });
      };
    },
    removeAttachment(index) {
      const returned = [];
      let x = -1;
      this.edit.attachments.forEach((attachment) => {
        x += 1;
        if (x !== index) {
          returned.push(attachment);
        }
        this.edit.attachments = returned;
      });
    },
    shortenFileName(string) {
      if (string.length > 12) {
        return `${string.substring(0, 12)}...`;
      }
      return string;
    },
    editPost() {
      this.visible = true;
      const uploads = [];
      this.edit.attachments.forEach((attachment) => {
        uploads.push(attachment.id);
      });
      let poll = {};
      if (
        this.post.poll.question &&
        this.post.poll.endDate &&
        this.post.poll.options[0].questionText &&
        this.post.poll.options[1].questionText &&
        this.newEndDate &&
        this.newEndTime
      ) {
        poll.question = this.post.poll.question;
        poll.endDate = this.getFormattedDate(this.newEndDate, this.newEndTime);
        poll.options = this.post.poll.options;
        poll.pollUuid = this.post.poll.pollUuid;
      } else poll = null;
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}/posts/${this.post.id}`,
          {
            title: this.edit.title,
            content: this.edit.content,
            media: uploads,
            pinned: this.post.pinned,
            poll,
          },
          {
            headers: { authorization: sessionStorage.getItem('vwcup-token') },
          },
        )
        .then(() => {
          setTimeout(() => {
            if (this.post.private === 0) {
              this.$router.replace(`/forum/${this.post.forumId}`);
            } else if (this.post.private === 1) {
              this.$router.replace(`/group/${this.post.forumId}`);
            }
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.data.error === 'jwt expired') {
            this.$store.dispatch('logout')();
          }
        })
        .finally(() => {
          this.visible = false;
        });
    },
    checkForOGTag() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/media/og-tag-check`,
          {
            text: this.firstValidURL,
          },
          {
            headers: { authorization: sessionStorage.getItem('vwcup-token') },
          },
        )
        .then((response) => {
          if (!response.error) {
            this.ogTag = response.data;
          } else {
            this.ogTag = null;
          }
        })
        .catch((error) => {
          // this.ogTag = null;
          console.error(error);
        });
    },
  },
  watch: {
    firstValidURL() {
      this.checkForOGTag();
    },
  },
  created() {
    this.visible = true;
    axios
      .get(`${process.env.VUE_APP_API_URL}/posts/${this.$route.params.id}/`, {
        headers: { authorization: sessionStorage.getItem('vwcup-token') },
      })
      .then((response) => {
        // eslint-disable-next-line prefer-destructuring
        this.post = response.data.data.posts[0];
        this.edit.id = this.post.id;
        this.edit.title = this.post.title;
        this.edit.content = this.post.content;
        this.edit.attachments = this.post.media;
        const endDate = new Date(this.post.poll.endDate);
        this.newEndDate = endDate;
        let hours = endDate.getHours();
        let minutes = endDate.getMinutes();
        if (hours < 10) hours = `0${hours}`;
        if (minutes < 10) minutes = `0${minutes}`;
        let meridian;
        if (hours > 12) {
          meridian = 'PM';
        } else {
          meridian = 'AM';
        }
        this.newEndTime = `${hours}:${minutes} ${meridian}`;

        if (this.post.author !== this.$store.state.User.userInfo.userID) {
          this.post = null;
          this.$router.push('/');
        }
      })
      .catch((error) => {
        // handle error
        console.error(error);
      })
      .finally(() => {
        this.visible = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.edit-post {
  .breadcrumb {
    font-weight: 300;
    font-size: 1.2rem;
    margin-bottom: 36px;
    cursor: pointer;
    .back {
      cursor: pointer;
    }
    .blue {
      color: $primary-light;
      font-weight: 700;
    }
  }
  .post {
    padding-top: 35px;
    padding-bottom: 35px;
    .about {
      margin-bottom: 25px;
    }
    .title {
      .header {
        font-family: $head-font-stack;
        font-weight: 700;
      }
      input {
        width: calc(100% - 30px);
        margin-top: 5px;
        margin-bottom: 25px;
      }
    }
    .body {
      margin-bottom: 25px;
      .header {
        font-family: $head-font-stack;
        font-weight: 700;
      }
      .wysiwyg {
        margin-top: 5px;
      }
    }
    .upload-attachments {
      font-family: $head-font-stack;
      font-weight: 700;
      font-size: 1.4rem;
      margin-bottom: 25px;
      .header {
        margin-bottom: 10px;
      }
      .upload {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 400px;
        .left {
          opacity: 0.5;
          font-weight: 300;
          font-size: 1.4rem;
          margin-right: auto;
          @include md {
            margin-right: 50px;
          }
        }
        .right {
          font-weight: 700;
          font-size: 1.4rem;
          color: $primary-light;
          display: flex;
          align-items: center;
          cursor: pointer;
          svg {
            width: 15px;
            padding-right: 5px;
          }
          input {
            height: 18px;
            width: 200px;
            opacity: 0;
            margin-left: -200px;
            padding: 0;
            * {
              cursor: pointer;
            }
            cursor: pointer;
          }
        }
      }
    }
    .button {
      .primary-btn {
        background: $primary-dark;
        border: 1px solid $primary-dark;
        color: $primary-white;
        margin: 0 auto;
        display: inline-block;
        padding: 10px 33px;
        &:hover {
          background: $primary-white;
          border: 1px solid $primary-dark;
          color: $primary-dark;
        }
        &.disabled {
          color: #707070;
          background: $light-grey;
          pointer-events: none;
          border-color: $light-grey;
        }
      }
      .taggedUsers {
        background: $primary-dark;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 3px;
        width: calc(100% - 30px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 15px;
        font-size: 1.4rem;
        .users {
          font-family: $text-font-stack;
          color: $primary-white;
          font-weight: 300;
          max-width: 80%;
          .bold {
            font-weight: 700;
          }
        }
        .post {
          font-size: 1.4rem;
          font-weight: 700;
          color: $primary-light;
          margin-left: 10px;
          cursor: pointer;
          box-shadow: none;
          background: $primary-dark;
        }
      }
    }
  }
}
.about {
  @include lg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .about-title {
    margin-bottom: 20px;
    word-break: break-all;
    @include md {
      margin-bottom: 0;
    }
    font-family: $head-font-stack;
    font-weight: 300;
    font-size: 2rem;
  }
  .white {
    background: $primary-white;
    color: $primary-dark;
    border: 1px solid $primary-dark;
    &:hover {
      color: $primary-dark;
      background-color: $primary-light;
      border-color: $primary-light;
    }
  }
  .primary-btn {
    margin-bottom: 15px;
    width: 100%;
    @include md {
      width: auto;
    }
    @include lg {
      margin-bottom: 0;
    }
  }
}
.thumbnails {
  margin-bottom: 15px;

  .thumbnail {
    width: 200px;
    height: 100px;
    margin-right: 15px;
  }
  .thumbnail-container {
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    .fileName {
      margin-bottom: 15px;
      font-weight: 300;
      font-size: 1.4rem;
      width: 150px;
      @include md {
        margin-right: 50px;
      }
    }
    .remove {
      font-weight: 700;
      font-size: 1.4rem;
      color: $danger;
      cursor: pointer;
    }
    .attachment {
      width: 135px;
      height: 82px;
      margin-right: 20px;
      margin-bottom: 20px;
      background: $light-grey;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}

.poll {
  margin-bottom: 25px;
  .header {
    font-family: $head-font-stack;
    font-weight: 700;
    font-size: 1.4rem;
    display: flex;
    margin-bottom: 10px;
  }
  .poll-inner {
    border: 1px dashed #b6bfc5;
    padding: 15px;
    .title {
      font-family: $head-font-stack;
      font-weight: 700;
      font-size: 1rem;
      display: flex;
      margin-bottom: 10px;
      margin-top: 25px;
      &.no-margin {
        margin-top: 0;
      }
      &.lessMargin {
        margin-bottom: 5px;
      }
    }
    input {
      width: calc(100% - 30px);
      &:disabled {
        background: #dfe4e880;
      }
      &.date {
        &:before {
          background: url('../assets/icons/calendar.svg?external') no-repeat center center;
          content: '';
          height: 22px;
          left: 15px;
          position: absolute;
          top: 15px;
          width: 22px;
        }
      }
    }
    .date-time {
      display: flex;
      align-items: center;
      .end-date,
      .end-time,
      .est {
        width: 175px;
        margin-right: 15px;
      }
    }
  }
}

.links {
  text-align: center;
  margin: 20px 0px 35px 0px;
  box-shadow: 0px 2px 6px #0000001a;
  .image-container {
    background-color: #dfe4e840;
    margin-bottom: 20px;
    display: flex;
    padding-left: 35px;
    height: 300px;
    .img {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 300px;
      }
      svg {
        flex: 1;
        width: 75px;
        height: 75px;
      }
    }
  }
  .closeOGTag {
    width: 35px;
    height: 35px;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      circle {
        fill: $primary-light;
      }
    }
  }
  .meta {
    display: flex;
    padding: 0 20px;
    .left {
      flex: 1;
      padding-right: 20px;
      text-align: left;
      .title {
        font-family: $head-font-stack;
        font-weight: 300;
        font-size: 1.4rem;
      }
      .description {
        font-family: $head-font-stack;
        font-weight: bold;
        font-size: 1.6rem;
        margin-top: 8px;
        margin-bottom: 20px;
      }
    }
    .right {
      color: $primary-light;
      font-weight: bold;
      font-size: 1.4rem;
      padding-right: 20px;
      text-align: right;
      cursor: pointer;
    }
  }
}

.flex-input-wrapper {
  @include md {
    display: flex;
    justify-content: space-between;
    width: 480px;
  }
  .input-wrapper {
    margin-bottom: 10px;
    @include md {
      margin-bottom: 0;
    }
    &.mid-width {
      width: 228px;
    }
    &.time-width {
      width: 100px;
    }
    &.small-width {
      width: 175px;
    }
    &.no-label {
      display: flex;
      align-items: flex-end;
    }
  }
}

::v-deep .vdp-datepicker {
  position: relative;
  // pointer-events: none;

  input[type='text'] {
    // background: $light-grey;
    font-family: $text-font-stack;
    font-weight: 300;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0px;
    line-height: 17px;
    margin-top: 5px;
    padding: 5px 5px 5px 47px;
    width: 175px;

    &:focus,
    &:active {
      outline: #00b0f0 auto 1px;
    }
  }

  &:before {
    background: url('../assets/icons/calendar.svg?external') no-repeat center center;
    content: '';
    height: 22px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 22px;
  }
}

::v-deep .time-picker {
  // pointer-events: none;
  position: relative;
  width: 100% !important;

  .clear-btn {
    height: 40px;
    margin-top: 7.5px;
    margin-right: 5px;
  }

  input[type='text'] {
    // background: $light-grey;
    font-family: $text-font-stack;
    font-weight: 300;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0px;
    line-height: 17px;
    margin-top: 5px;
    padding: 5px 5px 5px 47px;
    width: 100%;

    &:focus,
    &:active {
      outline: #00b0f0 auto 1px;
    }
  }

  &:before {
    background: url('../assets/icons/clock_icon.svg?external') no-repeat center center;
    content: '';
    height: 22px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 22px;
  }

  .dropdown {
    left: 7px;
    top: calc(2.2em + 6px);
  }
}

::v-deep {
  .vue__time-picker {
    .active {
      background: $primary-light !important;
    }
  }
}
</style>
